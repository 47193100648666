import { Routes, Route, useLocation } from 'react-router-dom';

import styled, { ThemeProvider } from 'styled-components';

import { Reset } from 'styled-reset';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { useEffect } from 'react';
import GlobalStyle from './styles/GlobalStyle';
import defaultTheme from './styles/defaultTheme';

import AreaPage from './pages/AreaPage';
import HomePage from './pages/HomePage';
import AcademyPage from './pages/AcademyPage';
import AcademiesPage from './pages/AcademiesPage';
import AreasPage from './pages/AreasPage';
import ArticlesPage from './pages/PostsPage';
import ArticlePage from './pages/ArticlePage';
import PostPage from './pages/PostPage';

const sendPageView = (url: string) => {
  window.gtag('config', 'GA_MEASUREMENT_ID', {
    page_path: url,
  });
};

const Main = styled.main`
  max-width: ${(props) => props.theme.sizes.layoutMaxWidth};
  margin-inline: auto;
`;

export default function App() {
  if (window.location.hostname.includes('clik')) {
    const gmtScript = document.createElement('script');
    gmtScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-WK7XVCBX');`;

    const gaScript = document.createElement('script');

    gaScript.async = true;
    gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-1F7Y0B25D2';

    const gaScript2 = document.createElement('script');
    gaScript2.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-1F7Y0B25D2');`;

    [gaScript, gaScript2, gmtScript].forEach((s) => {
      document.head.appendChild(s);
    });
  }

  const location = useLocation();

  useEffect(() => {
    if (window.location.hostname.includes('clik')) {
      sendPageView(location.pathname);
    }
  }, [location.pathname]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  const theme = defaultTheme;
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Reset />
        <GlobalStyle />
        <Main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/area/:id" element={<AreaPage />} />
            <Route path="/academy/:id" element={<AcademyPage />} />
            <Route path="/academies" element={<AcademiesPage />} />
            <Route path="/areas" element={<AreasPage />} />
            <Route path="/posts" element={<ArticlesPage />} />
            <Route path="article/:id" element={<ArticlePage />} />
            <Route path="post/:slug" element={<PostPage />} />
          </Routes>
        </Main>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
