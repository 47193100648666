/* eslint-disable class-methods-use-this */
import axios from 'axios';

import Area from '../models/Area';
import Academy from '../models/Academy';
import Schedule from '../models/Schedule';
import AcademyItem from '../models/AcademyItem';
import CompareProgramType from '../models/CompareProgramType';
import CompareAcademyResult from '../models/CompareAcademyResult';
import OgData from '../models/OgData';
import client from './contentfulService';
import Post from '../models/Post';

const baseURL = process.env.API_BASE_URL || 'http://localhost:8000';

export default class ApiService {
  async fetchArea(id: number): Promise<Area> {
    const path = `areas/${id}`;
    const url = `${baseURL}/${path}`;

    const { data } = await axios.get(url);

    const { area } = data;

    return area;
  }

  async fetchAreas(count: number | undefined): Promise<Area[]> {
    const path = 'areas';
    const url = `${baseURL}/${path}${count ? `?count=${count}` : ''}`;

    const { data } = await axios.get(url);

    const { areas } = data;

    return areas;
  }

  async fetchAcademy(id: number): Promise<Academy> {
    const path = `academies/${id}`;
    const url = `${baseURL}/${path}`;

    const { data } = await axios.get(url);

    const { academy } = data;

    return academy;
  }

  async fetchPost(slug: string): Promise<Post | null> {
    const response = await client.getEntries({
      content_type: 'post',
      'fields.slug': slug,
    });

    if (!response.items.length) {
      return null;
    }

    const p = response?.items?.[0];

    const tagIds = [p]
      .flatMap((item) => item.metadata.tags.map((tag) => tag.sys.id))
      .filter((id, index, self) => self.indexOf(id) === index);

    // 태그 ID로 태그 이름 가져오기
    const tagNameMap = await this.fetchTagNames(tagIds);

    const result = {
      ...p.fields,
      coverImage: p.fields.coverImage?.fields.file.url,
      author: {
        name: p.fields.author?.fields.name,
        imageUrl: p.fields.author?.fields.picture.fields.file.url,
      },
      tags: p.metadata.tags.map((tag) => ({
        id: tag.sys.id,
        name: tagNameMap[tag.sys.id] || 'Unknown', // 태그 이름 매핑
      })),
    };

    return result;
  }

  async fetchTagNames(tagIds: string[]): Promise<{ [key: string]: string }> {
    if (tagIds.length === 0) return {};

    // 모든 태그 가져오기
    const response = await client.getTags(); // `getTags()` 호출
    const tags = response.items;

    // 태그 ID ↔ 태그 이름 매핑
    return tags.reduce((map, tag) => {
      if (tagIds.includes(tag.sys.id)) {
        map[tag.sys.id] = tag.name;
      }
      return map;
    }, {} as { [key: string]: string });
  }

  async fetchPosts(count: number | null): Promise<Post[]> {
    // 콘텐츠 가져오기
    const response = await client.getEntries({ content_type: 'post' });

    // 모든 태그 ID 추출 (중복 제거)
    const tagIds = response.items
      .flatMap((item) => item.metadata.tags.map((tag) => tag.sys.id))
      .filter((id, index, self) => self.indexOf(id) === index);

    // 태그 ID로 태그 이름 가져오기
    const tagNameMap = await this.fetchTagNames(tagIds);

    const result = response.items.map((item) => ({
      ...item.fields,
      coverImage: item.fields.coverImage?.fields.file.url,
      author: {
        name: item.fields.author?.fields.name,
        imageUrl: item.fields.author?.fields.picture.fields.file.url,
      },
      tags: item.metadata.tags.map((tag) => ({
        id: tag.sys.id,
        name: tagNameMap[tag.sys.id] || 'Unknown', // 태그 이름 매핑
      })),
    }));

    if (count) {
      return result.slice(0, count);
    }

    // 결과 매핑
    return result;
  }

  async fetchAcademies(count: number | undefined): Promise<Academy[]> {
    const path = 'academies';
    const url = `${baseURL}/${path}${count ? `?count=${count}` : ''}`;

    const { data } = await axios.get(url);

    const { academies } = data;

    return academies;
  }

  async fetchSchedules(): Promise<Schedule[]> {
    const path = 'schedules';
    const url = `${baseURL}/${path}`;

    const { data } = await axios.get(url);

    const { schedules } = data;

    return schedules;
  }

  async fetchCompareAcademies(): Promise<AcademyItem[]> {
    const path = 'compare-academies';
    const url = `${baseURL}/${path}`;
    const { data } = await axios.get(url);

    const { academies } = data;

    return academies;
  }

  async fetchCompareAcademyResult(
    id1: number | null,
    id2: number | null,
    programType: CompareProgramType,
    voted: boolean,
  ): Promise<CompareAcademyResult> {
    const path = 'compare-academies';
    const url = `${baseURL}/${path}`;

    const { data } = await axios.post(url, {
      academyIds: [id1, id2],
      programType,
      voted,
    });

    return data;
  }

  async voteAcademy({
    id1, id2, programType, selectedId,
  }: {
    id1: number,
    id2: number,
    programType: CompareProgramType,
    selectedId: number,
  }) {
    const path = 'compare-like';
    const url = `${baseURL}/${path}`;

    const { data } = await axios.post(url, {
      academyIds: [id1, id2],
      programType,
      selectedId,
    });

    return data;
  }

  async fetchOgData(link: string): Promise<OgData> {
    const path = 'fetch-og-data';
    const url = `${baseURL}/${path}`;
    const response = await axios.post(url, { link });
    const parser = new DOMParser();
    const doc = parser.parseFromString(response.data, 'text/html');

    const getMetaContent = (property: string) => {
      const element = doc.querySelector(`meta[property='${property}']`);
      return element ? element.getAttribute('content') || '' : '';
    };

    function extractPath(url) {
      const regex = /https?:\/\/[^\/]+(\/[^?]*)/;
      const matches = url.match(regex);
      return matches ? matches[1] : null;
    }

    const getFavicon = () => {
      const element = doc.querySelector('link[rel*="icon"]') as HTMLLinkElement;
      return element ? extractPath(element.href) : '';
    };

    return {
      title: getMetaContent('og:title') || doc.title,
      type: getMetaContent('og:type'),
      image: getMetaContent('og:image'),
      url: getMetaContent('og:url'),
      description: getMetaContent('og:description') || getMetaContent('description'),
      siteName: getMetaContent('og:site_name'),
      favicon: getFavicon(),
    };
  }
}

export const apiService = new ApiService();
