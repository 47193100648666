import styled from 'styled-components';

const Image = styled.div<{url: string}>`
  aspect-ratio: 10 / 5;
  height: auto;
  margin-block: 3rem 5rem;
  background: url(${(props) => props.url}) no-repeat center center / cover;
`;

function ContentfulImage({ src }: {
  src: string;
}) {
  return <Image url={src} alt="" />;
}

export default ContentfulImage;
