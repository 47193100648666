import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';
import ContentfulImage from './ContentfuImage';
// import Link from 'next/link';
// import ContentfulImage from './ui/ContentfulImage';

const Blockquote = styled.blockquote`
      position: relative;
      background-color: #f9f9f9; /* 배경 색상 */
      margin-bottom: 3rem;
      padding: 1.5rem 1rem; /* 패딩 */
      padding-left: 2rem; /* 세로선 공간 확보 */
      line-height: 1.3;

      li {
        margin-left: 1.5rem;
        line-height: 1.6;
      }

      p:last-of-type {
        margin-bottom: 0;
      }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px; /* 세로선 너비 */
      background-color: #8B0000; /* 세로선 색상 */
    }
`;

const Hr = styled.hr`
  margin-block: 4rem;
`;

const StyledH4 = styled.h4`
  font-size: 1.6rem;
  font-weight: 700;
  margin-block: 2rem;
  margin-left: 2rem;

  + p {
    margin-left: 4rem;
    margin-bottom: 2rem;
  }

  +p+ol {
    margin-left: 4rem;
  }
`;

const options = {
  renderMark: {
    [MARKS.CODE]: (text) => (
      <pre>
        <code>{text}</code>
      </pre>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (
        node.content.find((item) => item.marks?.find((mark) => mark.type === 'code'))
      ) {
        return (
          <div>
            <pre>
              <code>{children}</code>
            </pre>
          </div>
        );
      }

      return <p>{children}</p>;
    },
    [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>, // Unordered list
    [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>, // Ordered list
    [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>, // List item

    [BLOCKS.QUOTE]: (node, children) => (
      <Blockquote>
        <p>{children}</p>
      </Blockquote>
    ),
    [INLINES.ENTRY_HYPERLINK]: (node) => {
      if (node.data.target.sys.contentType.sys.id === 'post') {
        return (
          <Link href={`/posts/${node.data.target.fields.slug}`}>
            {node.data.target.fields.title}
          </Link>
        );
      }
    },

    [INLINES.HYPERLINK]: (node) => {
      const text = node.content.find((item) => item.nodeType === 'text')?.value;
      return (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      );
    },

    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      if (node.data.target.sys.contentType.sys.id === 'videoEmbed') {
        return (
          <iframe
            height="400"
            width="100%"
            src={node.data.target.fields.embedUrl}
            title={node.data.target.fields.title}
            allowFullScreen
          />
        );
      }
    },
    [BLOCKS.HEADING_4]: (node, children) => <StyledH4>{children}</StyledH4>,
    [BLOCKS.HR]: () => <Hr />,
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <ContentfulImage
        src={node.data.target.fields.file.url}
        // height={node.data.target.fields.file.details.image.height}
        // width={node.data.target.fields.file.details.image.width}
      />
    ),
  },
};

function RichText({ content }) {
  return <>{documentToReactComponents(content, options)}</>;
}

export default RichText;
