import { useQuery } from '@tanstack/react-query';

import { apiService } from '../services/ApiService';
import Post from '../models/Post';

export default function useFetchPosts(count?: number | undefined) {
  const { isLoading, error, data } = useQuery<Post[]>({
    queryKey: ['posts'],
    queryFn: () => apiService.fetchPosts(count || null),
  });

  return { isLoading, error, data };
}
