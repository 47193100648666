import styled from 'styled-components';

type ArticleHeaderProps = {
  url: string;
}

const Header = styled.section<ArticleHeaderProps>`
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.3)), url(${(props) => props.url}) no-repeat center / cover;
  padding-inline: ${(props) => props.theme.sizes.inlineOffset};
  padding-block: 16rem 2.4rem;
  margin-inline: 0 !important;
  margin-bottom: 2.4rem;

  .tags {
    display: flex;
    font-size: 1.4rem;
    margin-bottom: 0;
    flex-wrap: wrap;
    padding: 0;
    gap: 1rem;
    list-style: none;

    li {
      color: #FFFFFF;
    }
  }

  h2 {
    margin-bottom: 1rem;
    line-height: 1.3;
  }

  > * {
    color: white;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding: 18rem 4rem 2.4rem;
    border-radius: 3rem;
    margin-bottom: 6.4rem !important;

    .sum, .date {
      font-size: 1.4rem;
    }

    .tags {
      font-size: 1em;

      li {
        font-size: 1.6rem;
      }
    }

    p, li {
      font-size: 1.8rem;
    }
  }
`;
export default Header;
