import resolveResponse from 'contentful-resolve-response';
import { freezeSys, toPlainObject, createRequestConfig } from 'contentful-sdk-core';
import mixinStringifySafe from './mixins/stringify-safe.js';

/**
 * Retrieves all the available pages for a sync operation
 */
async function pagedSync(http, query, options) {
    if (!query || (!query.initial && !query.nextSyncToken && !query.nextPageToken)) {
        throw new Error('Please provide one of `initial`, `nextSyncToken` or `nextPageToken` parameters for syncing');
    }
    if (query['content_type'] && !query.type) {
        query.type = 'Entry';
    }
    else if (query['content_type'] && query.type && query.type !== 'Entry') {
        throw new Error('When using the `content_type` filter your `type` parameter cannot be different from `Entry`.');
    }
    const defaultOptions = {
        withoutLinkResolution: false,
        withoutUnresolvableLinks: false,
        paginate: true,
    };
    const { withoutLinkResolution, withoutUnresolvableLinks, paginate } = Object.assign(Object.assign({}, defaultOptions), options);
    const response = await getSyncPage(http, [], query, { paginate });
    // clones response.items used in includes because we don't want these to be mutated
    if (!withoutLinkResolution) {
        response.items = resolveResponse(response, {
            removeUnresolved: withoutUnresolvableLinks,
            itemEntryPoints: ['fields'],
        });
    }
    // maps response items again after getters are attached
    const mappedResponseItems = mapResponseItems(response.items);
    if (response.nextSyncToken) {
        mappedResponseItems.nextSyncToken = response.nextSyncToken;
    }
    if (response.nextPageToken) {
        mappedResponseItems.nextPageToken = response.nextPageToken;
    }
    return freezeSys(mixinStringifySafe(toPlainObject(mappedResponseItems)));
}
/**
 * @private
 * @param items
 * @returns Entities mapped to an object for each entity type
 */
function mapResponseItems(items) {
    const reducer = (type) => {
        return (accumulated, item) => {
            if (item.sys.type === type) {
                accumulated.push(toPlainObject(item));
            }
            return accumulated;
        };
    };
    return {
        entries: items.reduce(reducer('Entry'), []),
        assets: items.reduce(reducer('Asset'), []),
        deletedEntries: items.reduce(reducer('DeletedEntry'), []),
        deletedAssets: items.reduce(reducer('DeletedAsset'), []),
    };
}
function createRequestQuery(originalQuery) {
    if (originalQuery.nextPageToken) {
        return { sync_token: originalQuery.nextPageToken };
    }
    if (originalQuery.nextSyncToken) {
        return { sync_token: originalQuery.nextSyncToken };
    }
    if (originalQuery.sync_token) {
        return { sync_token: originalQuery.sync_token };
    }
    return originalQuery;
}
/**
 * If the response contains a nextPageUrl, extracts the sync token to get the
 * next page and calls itself again with that token.
 * Otherwise, if the response contains a nextSyncUrl, extracts the sync token
 * and returns it.
 * On each call of this function, any retrieved items are collected in the
 * supplied items array, which gets returned in the end.
 */
async function getSyncPage(http, items, query, { paginate }) {
    const requestQuery = createRequestQuery(query);
    const response = await http.get('sync', createRequestConfig({ query: requestQuery }));
    const data = response.data || {};
    items = items.concat(data.items || []);
    if (data.nextPageUrl) {
        if (paginate) {
            delete requestQuery.initial;
            requestQuery.sync_token = getToken(data.nextPageUrl);
            return getSyncPage(http, items, requestQuery, { paginate });
        }
        return {
            items,
            nextPageToken: getToken(data.nextPageUrl),
        };
    }
    else if (data.nextSyncUrl) {
        return {
            items,
            nextSyncToken: getToken(data.nextSyncUrl),
        };
    }
    else {
        return { items: [] };
    }
}
/**
 * Extracts token out of an url
 * @private
 */
function getToken(url) {
    const urlParts = url.split('?');
    return urlParts.length > 0 ? urlParts[1].replace('sync_token=', '') : '';
}

export { pagedSync as default };
