import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Post from '../components/Post';
import useFetchPost from '../hooks/useFetchPost';

export default function PostPage() {
  const { slug } = useParams();

  if (!slug) {
    return null;
  }

  const { data: post, isLoading } = useFetchPost(slug);

  if (!post) {
    return null;
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Helmet>
        <title>{`${post.title} | CLIK`}</title>
        <meta name="keywords" content={post.tags.map((p) => p.name).join(', ')} />
        <meta name="description" content={post.excerpt} />
      </Helmet>
      <Header />
      <Post post={post} />
      <Footer />
    </>
  );
}
