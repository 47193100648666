import { useQuery } from '@tanstack/react-query';

import { apiService } from '../services/ApiService';
import Post from '../models/Post';

export default function useFetchPost(slug: string) {
  const { isLoading, error, data } = useQuery<Post | null>({
    queryKey: ['post'],
    queryFn: () => apiService.fetchPost(slug),
  });

  return { isLoading, error, data };
}
