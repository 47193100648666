import styled from 'styled-components';
import Post from '../../models/Post';
import ArticleHeader from '../../styles/ArticleHeader';
import Author from '../Posts/Author';

const Date = styled.div`
  font-size: 1.4rem;
  margin-bottom: .8rem;
`;

export default function PostHeader({ post }: {post: Post}) {
  return (
    <ArticleHeader url={post.coverImage}>
      <Date>{post.date.split('T')[0].replaceAll('-', '/')}</Date>
      <h2>{post.title}</h2>
      <ul className="tags">
        {post.tags.map((t) => (<li key={t.id}>{`#${t.name}`}</li>))}
      </ul>
      <Author author={post.author} />
    </ArticleHeader>
  );
}
