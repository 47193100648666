import styled from 'styled-components';
import Author from '../../models/Author';

const Container = styled.div<{url: string}>`
  position: relative;
  font-size: 1.23rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  height: 2.8rem;
  line-height: 2.8rem;
  padding-left: 3.5rem;
  color: #8A8A89;
  

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2.8rem;
    height: 2.8rem;
    background: url(${(props) => props.url}) no-repeat center / contain;
    border-radius: 50%;
  }
`;

export default function Author({ author }: {author: Author}) {
  return (
    <Container url={author.imageUrl}>{author.name}</Container>
  );
}
