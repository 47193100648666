import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Cards3 from '../common/Cards3';
import Academy from '../../models/Academy';
import Area from '../../models/Area';
import Compare from '../Compare';
import Articles from '../Posts';

import Post from '../../models/Post';

const Container = styled.div`
  margin-block: 10.5rem ${(props) => props.theme.sizes.inlineOffset};

  h2 {
    font-size: 2.8rem;
    font-weight: 700;
    padding-block: 4.8rem ${(props) => props.theme.sizes.inlineOffset};
    margin-bottom: 0;
  }

  > h2 {
    padding-top: 2.4rem;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-block: 15.5rem;
    padding-inline: 0;

    h2 {
      font-size: 3.2rem;
    }

    > h2 {
      padding-top: 7.2rem;
    }
  }
`;

const Section = styled.div`
  padding-inline: ${(props) => props.theme.sizes.inlineOffset};
  padding-bottom: 4rem;
  border-bottom: 1.6rem solid rgba(240, 240, 240, 1);

  &:last-of-type {
    border-bottom: 0;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    position: relative;
    border-bottom: 0;
  }
`;

const More = styled(Link)`
  display: block;
  padding-block: 2rem;
  text-align: center;
  color: rgba(153, 153, 153, 1);

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    position: absolute;
    top: 4.5rem;
    right: ${(props) => props.theme.sizes.inlineOffset};
    text-decoration: underline;
  }
`;

type HomeProps = {
  academies: Academy[];
  areas: Area[];
  posts: Post[];
}

export default function Home({ academies, areas, posts }: HomeProps) {
  const academyCards = academies.map((a) => ({
    id: a.id,
    title: a.name.split('\n')[0],
    subTitle: a.name.split('\n')[1],
    imageUrl: a.imageUrl,
  }));

  const areaCards = areas.map((a) => ({
    id: a.id,
    title: a.name.split('\n')[0],
    subTitle: a.name.split('\n')[1],
    imageUrl: a.imageUrl,
  }));
  return (
    <Container>
      <Section>
        <Compare />
      </Section>
      <Section>
        <h2>語学堂</h2>
        <Cards3 category="academy" cards={academyCards} />
        <More to="/academies">全体表示</More>
      </Section>
      <Section>
        <h2>地域</h2>
        <Cards3 category="area" cards={areaCards} />
        <More to="/areas">全体表示</More>
      </Section>
      <Section>
        <h2>最新情報文</h2>
        <Articles posts={posts} showAll={false} />
        <More to="/posts">全体表示</More>
      </Section>
    </Container>
  );
}
