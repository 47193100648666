import styled from 'styled-components';
import Post from '../../models/Post';
import ArticleArea from '../../styles/ArticleArea';

import RichText from './RichText';
import PostHeader from './PostHeader';

const Container = styled.div`
  margin-block: 6.4rem ${(props) => props.theme.sizes.inlineOffset};
  color: rgba(0, 0, 0, 1);


  ul {
    list-style: disc;
    padding-left: ${(props) => props.theme.sizes.inlineOffset};
    margin-bottom: 1rem;
  }

  p, li {
    word-break: break-all;
    line-height: 1.3
  }
  
  p > span {
    display: block;
  }

  a {
    text-decoration: underline;
  }

  .header-text {
    margin-bottom: 2.4rem;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    margin-top: 10.5rem;
    

    .header-text {
      margin-bottom: 4.8rem;
    }
  }
`;

export default function Post({ post }: {post: Post}) {
  return (
    <Container>
      <ArticleArea>
        <PostHeader post={post} />
        <RichText content={post.content} />
      </ArticleArea>
    </Container>
  );
}
