import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
    font-family: Noto Sans JP;
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
    word-break: keep-all;
    word-spacing: -.25px
  }

  a {
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
  }
  
  a:focus {
    outline: none;
  }

  button,
  input,
  textarea,
  select {
    color: ${(props) => props.theme.colors.text};
    font-family: inherit;
    font-size: inherit;
  }

  svg {
    display: inline-block;
    margin-top: -.4rem;
    vertical-align: middle;
  }

  h2 {
    font-size: 2.8rem;
    font-weight: 700;
    margin-bottom: 2rem;
    word-break: break-all;
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    word-break: break-all;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    h2 {
      font-size: 3.2rem;
    }

    h3 {
      font-size: 2.4rem;
    }
  }
`;

export default GlobalStyle;
