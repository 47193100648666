import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useMenu from '../hooks/useMenu';

const Container = styled.header`
  z-index: 12;
  position: fixed;
  top: 0;
  left: 0;
  padding: 2rem 2.4rem;
  width: 100%;
  background: #FFFFFF;
  border-bottom: 1px solid #CCCCCC;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    align-items: center;
    margin-inline: auto;
    width: ${(props) => props.theme.sizes.layoutMaxWidth};
  }
`;

const Logo = styled.h1`
  display: inline-block;
  width: 7.6rem;
  height: 2.4rem;
  text-indent: -10000px;
  background: url(/images/logo.png) no-repeat center / cover;
`;

type MenuType = {
  shown: boolean;
};

const Menu = styled.button<MenuType>`
  width: 2.2rem;
  height: 2.2rem;
  aspect-ratio: 76/24;
  border: 0;
  text-indent: -10000px;
  background: url(/images/icons/${(props) => (props.shown ? 'close' : 'menu')}.png) no-repeat center / 2.2rem;
  
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: none;
  }
`;

type NavigationProps = {
  shown: boolean;
}

const Navigation = styled.nav<NavigationProps>`
  position: fixed;
  display: ${(props) => (props.shown ? 'block' : 'none')};
  top: 6.4rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  padding: ${(props) => props.theme.sizes.inlineOffset};


  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    position: static;
    display: block;
    padding: 0;

    ul {
      display: flex;
    }
  }
`;

type ItemProps = {
  selected: boolean;
};

const Item = styled.li<ItemProps>`
  a {
    font-size: 2rem;
    display: inline-block;
    padding: .8rem 1.6rem;
    width: 8.8rem;
    color: #666666;
    background: ${(props) => (props.selected ? '#F0F0F0' : 'none')};
    text-align: center;
    border-radius: 1rem;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    border-radius: 2rem;
  }
`;

export default function Header() {
  const { shown, setShown } = useMenu();

  const location = useLocation();

  const handleMenuClick = () => {
    setShown(!shown);
  };
  return (
    <Container>
      <Wrapper>
        <Link to="/">
          <Logo>CLIK</Logo>
        </Link>
        <Menu
          type="button"
          shown={shown}
          onClick={handleMenuClick}
        >
          menu
        </Menu>
        <Navigation shown={shown}>
          <ul>
            <Item selected={location.pathname === '/'}>
              <Link to="/">ホーム</Link>
            </Item>
            <Item selected={location.pathname.startsWith('/academies')}>
              <Link to="/academies">語学堂</Link>
            </Item>
            <Item selected={location.pathname.startsWith('/areas')}>
              <Link to="/areas">地域</Link>
            </Item>
            <Item selected={location.pathname.startsWith('/posts')}>
              <Link to="/posts">情報</Link>
            </Item>
          </ul>
        </Navigation>
      </Wrapper>
    </Container>
  );
}
