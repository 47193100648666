import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PostModel from '../../models/Post';

import Author from './Author';

const Container = styled.div`
  margin-block: 0 ${(props) => props.theme.sizes.inlineOffset};

  h2 {
    font-size: 3.2rem;
    font-weight: 700;
    padding-top: ${(props) => props.theme.sizes.inlineOffset};
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-inline: 0;

    h2 {
      font-size: 4rem;
    }
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2%;

  .article {
    padding-block: 2rem;
    width: 100%;
    border-bottom: 1px solid rgba(240, 240, 240, 1);

  }
  

  :last-of-type {
    border: 0;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    flex-direction: row;

    .article {
      width: 49%;
      border-bottom: 1px solid rgba(240, 240, 240, 1);

    }
  }
`;

const Article = styled(Link)`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  .contents {
   
  }

  h3 {
    font-size: 1.6rem;
    font-weight: 400;
    margin-top: 2.4rem;
    margin: 0;
    line-height: 1.4
  }

  ul {
    font-size: 1.4rem;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin-top: .5rem;
    color: rgba(153, 153, 153, 1);
  }

  img {
    width: 14rem;
    height: 10rem;
    border-radius: 2rem;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    justify-content: space-between;
    .contents {
      
    }
  
    img {
      width: 20rem;
      height: 14rem;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
  min-height: 14rem;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    width: 45%;
  }
`;

const Image = styled.div<{url: string}>`
  width: 20rem;
  height: 14rem;
  background: url(${(props) => props.url}) no-repeat center / cover;
  border-radius: 2rem;
`;

const Date = styled.div`
  font-size: 1.2rem;
  color: #C1C1C3;
  margin-top: 1rem;
`;

type ArticlesProps = {
  posts: PostModel[];
  showAll?: boolean;
}

export default function Posts({ posts, showAll = true }: ArticlesProps) {
  const list = showAll ? posts : posts.slice(0, 8);
  return (
    <Container>
      <List>
        {list.map((a) => (
          <li className="article" key={a.slug}>
            <Article to={`/post/${a.slug}`}>
              <Content>
                <h3>{a.title}</h3>
                <ul>
                  {a.tags.map((t) => <li key={t.id}>{`#${t.name}`}</li>)}
                </ul>
                <Date>{`${a.date.split('T')[0]}`}</Date>
                <Author author={a.author} />
              </Content>
              <Image url={a.coverImage} />
            </Article>
          </li>
        ))}
      </List>
    </Container>
  );
}
