import styled from 'styled-components';

import useFetchCompareAcademies from '../../hooks/useFetchCompareAcademies';
import Program from './Program';
import SelectBoxes from './SelectBoxes';
import useCompareProgramType from '../../hooks/useCompareProgramType';
import useCompareSelect from '../../hooks/useCompareSelect';
import CompareResult from './CompareResult';
import useCompareResult from '../../hooks/useCompareResult';

const Container = styled.div`
  padding: 3.2rem ${(props) => props.theme.sizes.inlineOffset} 30rem;
  background: rgba(248, 248, 248, 1);
  border-radius: 2rem;

  strong {
    color: rgba(171, 31, 35, 1);
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding-inline: 8rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem !important;
  margin-bottom: 6rem;
  padding-bottom: 8rem !important;
  text-align: center;
  line-height: 1.4;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    font-size: 4rem !important;
    margin-inline: auto;
  }
`;

const Div = styled.div`
  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    display: inline;
  }
`;

export default function Compare() {
  const {
    programType, clickLeft, clickRight, flip,
  } = useCompareProgramType();

  const { selected: selected1, handleClick: handleClick1 } = useCompareSelect();
  const { selected: selected2, handleClick: handleClick2 } = useCompareSelect();

  const { isLoading, error, data: academies } = useFetchCompareAcademies();

  const { result } = useCompareResult({
    id1: selected1, id2: selected2, programType, voted: false,
  });

  const filterAcademies = !academies ? []
    : academies?.filter((a) => a.programTypes.some((p) => p === programType));

  return (
    <Container>
      <Title>
        <div>韓国の語学堂を比較して</div>
        <strong>自分にぴったりの学校</strong>
        <Div>を選びましょう！</Div>
      </Title>
      <Program
        clickLeft={clickLeft}
        clickRight={clickRight}
        flip={flip}
        selected={programType}
      />
      <SelectBoxes
        academies={filterAcademies || []}
        selected1={selected1}
        selected2={selected2}
        handleClick1={handleClick1}
        handleClick2={handleClick2}
      />
      {result && (
        <CompareResult
          result={result}
          academies={[
            ...academies.filter((a) => a.id === selected1 || a.id === selected2),
          ]}
          programType={programType}
        />
      )}
    </Container>
  );
}
