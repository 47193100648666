import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Articles from '../components/Posts';
import useFetchArticles from '../hooks/useFetchArticles';
import articleDatas from '../components/Posts/articleDatas';
import useFetchPosts from '../hooks/useFetchPosts';

const Container = styled.div`
  margin-top: 10.5rem;
  padding-inline: ${(props) => props.theme.sizes.inlineOffset};

  h2 {
    font-size: 3.2rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.desktop}px) {
    padding: 0;
    
    h2 {
      font-size: 4rem;
    }
  }
`;

export default function PostsPage() {
  const { isLoading, data: posts } = useFetchPosts();

  if (isLoading) {
    return <p>Loading...</p>;
  }
  console.log(posts);
  return (
    <>
      <Header />
      <Container>
        <h2>最新情報文</h2>
        <Articles posts={posts} />
      </Container>
      <Footer />
    </>

  );
}
